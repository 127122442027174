import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { postMethod, putMethod, getMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Cancel = ({ location, userDetails }) => {

    const [reason1, setReason1] = useState(null);
    const [reason2, setReason2] = useState(null);
    const [reason3, setReason3] = useState(null);
    const [reason4, setReason4] = useState(null);
    const [reason5, setReason5] = useState(null);
    const [reason6, setReason6] = useState(null);
    const [reason7, setReason7] = useState(null);
    const [reason8, setReason8] = useState(null);
    const [reason9, setReason9] = useState(null);
    const [reason10, setReason10] = useState(null);
    const [remarks, setRemarks] = useState(null);

    var cancelInProgress = false;

    function confirm() {

        if(reason1===null && reason2===null &&  reason3===null && reason4===null && reason5===null && reason6===null && reason7===null && reason8===null && reason9===null && reason10===null){
            confirmAlert({
                title: "Alert",
                message: "Please select atleast one reason",
                buttons: [
                    {
                        label: 'OK',
                        className: 'btn w-100 h5'
                    }]
            });

            return;
        }

        let buttons = [
            {
                label: 'Keep Lesson',
                //className: 'bg-red'
            },
            {
                label: 'Cancel and Reschedule',
                onClick: () => postFeedback(false),
                className: 'bg-blue'
            },
            {
                label: 'Cancel and Donate',
                onClick: () => postFeedback(true),
                className: 'bg-green'
            },
        ];

        if(userDetails.user_role==='faculty'){
            buttons = [
                {
                    label: 'Yes',
                    onClick: () => postFeedback(false),
                    className: 'bg-red btn w-100 h5 no-padding'
                },
                {
                    label: 'No',
                    className: 'bg-green btn w-100 h5 no-padding'
                }
            ];
        }
        
        let cancelTitle = "Are you sure you want to cancel this lesson ?"
        let cancelMsg = "";

        if(userDetails.user_role==='faculty'){
            if((new Date(location.state.booking.start_time).getTime()- new Date().getTime())< (3*60*60*1000)){
                cancelMsg = "50% Cancellation fee will be applicable";
            }

        }else if(((new Date(location.state.booking.end_time).getTime() - new Date(location.state.booking.start_time).getTime())===60000) && ((new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)) <= 6 && (new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)>3){
            let cancelValue = (new Date(location.state.booking.end_time).getTime() - new Date(location.state.booking.start_time).getTime())/(1000*60*60)
            cancelMsg = `50% Cancellation fee will be applicable and donated.`

            buttons = [
                {
                    label: 'Keep Lesson',
                    //className: 'bg-red'
                },
                {
                    label: 'Cancel and Donate(50%)',
                    onClick: () => postFeedback("partial"),
                    className: 'bg-green'
                },
            ];

            
        }else if((new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)<3){
            cancelMsg = `100% Cancellation fee will be applicable and donated.`
            buttons = [
                {
                    label: 'Keep Lesson',
                    //className: 'bg-red'
                },
                {
                    label: 'Cancel and Donate',
                    onClick: () => postFeedback(true),
                    className: 'bg-green'
                },
            ];
        }

        confirmAlert({
            title: cancelTitle,
            message: cancelMsg,
            buttons: buttons
        });
    }

    async function postFeedback(donate) {


        if(cancelInProgress) return false;

        cancelInProgress = true;

        let cancelledBy = location.state.booking.booked_by.id;

        let reason = '';
        if (reason1)
            reason = reason1;
        if (reason2)
            reason += `,${reason2}`;
        if (reason3)
            reason += `,${reason3}`;
        if (reason4)
            reason += `,${reason4}`;
        if (reason5)
            reason += `,${reason5}`;
        if (reason6)
            reason += `,${reason6}`;
        if (reason7)
            reason += `,${reason7}`;
        if (reason8)
            reason += `,${reason8}`;
        if (reason9)
            reason += `,${reason9}`;
        if (reason10)
            reason += `,${reason10}`;


        let resp = await postMethod('/cancel-bookings', { booking: location.state.booking.id, reasons: reason, remarks: remarks, Cancelled_by: cancelledBy, donate: donate });
        let resp2 = await postMethod('/cancelbooking',{id:location.state.booking.id, donate: donate });
        if (resp.error) {
            notify.show("Error: ", resp.error);
        } else {
            setTimeout(() => notify.show("Cancelled successfully", "success"), 100);
            navigate('/dashboard?v='+window.localStorage.getItem('appVersion'))
        }

    }

    if (!location.state) {
        location.state = {}
    }

    useEffect(async ()=>{
        let booking = await getMethod(`/bookings/${location.state.booking.id}`);
        if(booking.data.isCancelled){
            confirmAlert({
                title: "Alert",
                message: "Lesson already cancelled",
                buttons: [
                    {
                        label: 'OK',
                        className: 'btn w-100 h5',
                        onClick: ()=>{navigate(-1)}
                    }]
            });
            
        }
    },[])

    return (
        <Layout title="Cancel">
            <Seo title="Cancel" />
            <div style={{display:'block'}}>
            <Booking booking={location.state.booking} />
            <div className="cancel-section" style={{marginBottom:'150px'}}>
                <div className="container">
                    <h5>Reason for Cancelling</h5>
                    <form className="cancelClass mb-3">

                        {userDetails.user_role==='student' && <><div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Technical Issues" onClick={event => { setReason1(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Technical Issues</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Personal Emergency" onClick={event => { setReason2(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Personal Emergency</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Personal Health" onClick={event => { setReason3(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Personal Health</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Family Responsibilities" onClick={event => { setReason4(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Family Responsibilities</label >
                        </div></>}

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Internet Issues" onClick={event => { setReason5(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Internet Issues</label >
                        </div>
                        {userDetails.user_role==='faculty' && <><div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Dislike Student Behaviour" onClick={event => { setReason6(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Dislike Student Behaviour</label >
                        </div></>}
                        {userDetails.user_role==='student' && <><div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Change Teacher" onClick={event => { setReason6(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Change Teacher</label >
                        </div></>}
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Time not suitable" onClick={event => { setReason7(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Time not suitable</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Salah Time" onClick={event => { setReason8(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Salah Time</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Booked by mistake" onClick={event => { setReason9(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Booked by mistake</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Other commitments" onClick={event => { setReason10(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Other commitments</label >
                        </div>
                    </form>
                    <label className="sm">Remarks</label>
                    <textarea className="form-control" rows="3" cols="45" name="remark" placeholder="Enter your remarks" onChange={event => setRemarks(event.target.value)}></textarea>
                    <div className="fixed-btn-wrapper">
                        <button type="button" className="btn btn-danger w-100" onClick={() => confirm()}>Cancel</button>
                    </div>
                </div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, userDetails: state.loginReducer.authenticate.user }), null)(Cancel);
